import React, { useCallback, useEffect, useState } from 'react';

import { FaExclamationCircle } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import {
  Attention,
  AvatarWaitlist,
  Container,
  CopyAlert,
  MemberSide,
  VideoSide,
} from './styles';
import Player from '~/components/Player';

import attention from '~/assets/icons/attention-icon.svg';
import arrows from '~/assets/icons/arrows-double-right.svg';
import thumbFree from '~/assets/defaults/thumb-free.webp';
import copy from '~/assets/icons/copy.svg';

interface IUser {
  name: string;
  email: string;
  temp_pass: string;
  referral_code: string;
  avatar_url: string;
}

const Aic: React.FC = () => {
  const [copied, setCopied] = useState(false);
  const [btnDownloadClicked, setBtnDownloadClicked] = useState(false);
  const [user, setUser] = useState<IUser>();

  useEffect(() => {
    const userData = localStorage.getItem('@AutoAffiliate:userData');

    if (userData) {
      const parseUserData = JSON.parse(userData);
      setUser(parseUserData);
    }
  }, []);

  console.log(user);

  const copyUrl = useCallback(() => {
    const el = document.createElement('textarea');
    el.value = `${process.env.REACT_APP_URL}/i/${user?.referral_code}`;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 1000);
  }, [user?.referral_code]);

  const handleShare = useCallback(async () => {
    try {
      await navigator.share({
        url: `https://autoaffiliate.ai/i/${user?.referral_code}`,
      });
    } catch (error) {
      // console.error('Error sharing:', error);
    }
  }, [user?.referral_code]);

  return (
    <Container>
      <div className="container-fluid">
        <div className="row justify-content-between">
          <Attention className="col-12 px-0">
            <div className="container zoom">
              <div className="row">
                <div className="col zoom">
                  <h2 className="h6 h5-sm h4-xl text-white mb-0 py-3 text-center">
                    <FaExclamationCircle />
                    <b className="ms-3">ATTENTION: </b>
                    You{' '}
                    <b>
                      <u>Must Watch This Video</u>
                    </b>{' '}
                    To Setup Your Bot!
                  </h2>
                </div>
              </div>
            </div>
          </Attention>
          <VideoSide className="col-lg-8 mx-auto">
            <div className="">
              <h1 className="ps-lg-4 mt-4">Congrats on Taking Action!</h1>
              <p className="ps-lg-4">
                Let’s keep it going and install your Bot now
              </p>
              <Player
                id="video-modal"
                src="https://cdn.autoaffiliate.ai/videos/video-aic-page.mp4"
                thumbnail={thumbFree}
                autoPlay
              />
              <a
                href="https://chromewebstore.google.com/search/PostEASE"
                target="_blank"
                className="btn-download w-100 d-block mt-4"
                rel="noreferrer"
                onClick={() => setBtnDownloadClicked(true)}
              >
                Click Here to Download & Install Your Connector Bot
              </a>
            </div>
          </VideoSide>
          <MemberSide className="col-lg-3 p-3">
            <div className="text-center welcome">
              <div className="text-center">
                <img src={attention} alt="Attention icon" className="mb-3" />
                <h2>
                  Welcome AutoAffiliate <br />
                  <span>Next Step:</span>
                </h2>
              </div>
              <hr />
              <h3 className="mb-3">
                Watch the videos carefully without distraction and follow the
                action plan. Once you have connected your bot,{' '}
                <span className="fw-bold">
                  click the button below to test the connection
                </span>
              </h3>
              <Link
                to={`${process.env.PUBLIC_URL}/mc`}
                className={`${
                  btnDownloadClicked ? 'pulse' : 'disabled'
                } btn-connection d-block`}
              >
                Activate Your Bot <img src={arrows} alt="Double arrows" />
              </Link>
            </div>
            <div className="mt-4 member">
              <div className="bg-title">
                <h2 className="title-waitlist text-center mb-0">
                  Member Status
                </h2>
              </div>
              <div className="pad-waitlist">
                <div className="text-center w-auto">
                  <div className="w-100 p-relative mb-4">
                    <AvatarWaitlist src={user?.avatar_url || ''} />
                  </div>
                  <div className="d-flex align-items-center justify-content-center">
                    <h2 className={` h5 mb-0 fw-bold`}>{user?.name}</h2>
                  </div>
                  <p className="new text-center">New member</p>
                </div>
                <div className="d-flex boxes">
                  <div className="w-100 text-center">
                    <h3>Log In Credentials:</h3>
                    <p>
                      <span>Email:</span> {user?.email}
                    </p>
                    <p>
                      <span>Password:</span> {user?.temp_pass}
                    </p>
                  </div>
                </div>
                <div className="text-center">
                  <p className="text-copy mt-4 mb-2">
                    Refer your friends & earn rewards
                  </p>
                  <button
                    className="d-none d-lg-flex btn-copy justify-content-between"
                    type="button"
                    onClick={copyUrl}
                  >
                    {`autoafiiliate.ai/i/${user?.referral_code}`}
                    <img src={copy} alt="copy" className="ml-2" />
                  </button>

                  <button
                    type="button"
                    className="d-flex d-lg-none btn-copy justify-content-between"
                    onClick={handleShare}
                  >
                    {`autoaffiliate.ai/i/${user?.referral_code}`}

                    <img src={copy} alt="Copy" className="ml-auto" />
                  </button>
                </div>
              </div>
            </div>
          </MemberSide>
        </div>
      </div>
      <CopyAlert show={copied} className="alert alert-success">
        <p className="mb-0">Copied</p>
      </CopyAlert>
    </Container>
  );
};

export default Aic;
