import { darken } from 'polished';
import styled from 'styled-components';
import { Modal as ModalComponent } from 'react-bootstrap';

export const Banner = styled.div`
  background-position: center;
  background-size: cover;
  min-height: 100vh;

  p {
    color: #151517 !important;
    font-size: 22px;
    font-family: 'Archivo', sans-serif;
    font-weight: 300;
    line-height: 35px;
    span {
      font-weight: 600;
      color: #151517;
    }
  }

  .video {
    button {
      display: none !important;
      pointer-events: none;
    }
  }

  video {
    border: 1px solid #303030;
    -webkit-box-shadow: 0px 43px 79px -1px rgba(0, 0, 0, 0.21);
    -moz-box-shadow: 0px 43px 79px -1px rgba(0, 0, 0, 0.21);
    box-shadow: 0px 43px 79px -1px rgba(0, 0, 0, 0.21);
    pointer-events: none;
  }

  h1 {
    color: #000;
    font-size: 34px;
    font-family: 'Bebas Neue', sans-serif;
    font-weight: 400;
    span {
      color: #116bff;
    }
  }

  .btn-yes {
    color: #fff;
    text-align: center;
    font-family: 'Archivo';
    font-size: 30px;
    font-weight: 700;
    line-height: 120.768%;
    letter-spacing: -0.68px;
    border-radius: 22px;
    background: #116bff;
    border: none;
    padding: 23px;
  }

  .container {
    max-width: 1820px;
  }

  .btn-mute {
    z-index: 100;
    background: transparent;
    border-radius: 20px;
    left: 0px;
  }

  .mute {
    position: absolute;
    z-index: 100;
    height: 400px;
    width: 400px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 8px;
    background: transparent;
    border-radius: 20px;
    border: 1px solid #fff;
    background: rgba(17, 107, 255, 0.8);
    backdrop-filter: blur(5px);
    filter: drop-shadow(0px 4px 40px rgba(17, 107, 255, 0.36));
    color: #fff;
    text-align: center;
    font-family: 'Archivo';
    font-size: 34px;
    font-weight: 700;
    line-height: 43.5px;
  }

  .btn-chat {
    background: transparent;

    border: none;

    width: 60px;
    height: 60px;
  }
  .btn-save {
    width: 100%;
    font-weight: 600;
    font-size: 22px;
    line-height: 27px;
    color: #ffffff;
    background: #116bff;
    border-radius: 20px;
    transition-duration: 0.3s;
    padding: 26px;

    :hover {
      opacity: 0.8;
    }
  }

  .logo {
    right: 2rem;
    top: 2rem;
  }

  .bg-right-side {
    background: #f4f4f4;
  }

  @media screen and (max-width: 1699px) {
    h1 {
      font-size: 31px;
    }
    .left-side {
      max-width: 1050px;
      margin: 0 auto;
    }
  }

  /* @media screen and (max-width: 1499px) {
    .left-side {
      max-width: 950px;
      margin: 0 auto;
    }
  } */

  @media screen and (max-width: 1399px) {
    h1 {
      font-size: 2vw;
    }

    p {
      font-size: 1.5vw;
    }
    .btn-save {
      font-size: 1.5vw;
      padding: 20px 26px;
    }
    .btn-yes {
      font-size: 24px;
    }
  }

  @media screen and (max-width: 1199px) {
    p {
      font-size: 1.3vw;
    }

    .btn-yes {
      font-size: 22px;
    }
  }

  @media screen and (max-width: 991px) {
    button {
      font-size: 20px;
    }

    h1 {
      font-size: 33px;
      text-align: center;
    }

    p {
      font-size: 18px;
      text-align: center;
    }

    .btn-save {
      font-size: 22px;
      padding: 20px 15px;
    }

    .btn-yes {
      font-size: 24px;
    }

    .mute {
      height: 370px;
    }
  }

  @media screen and (max-width: 768px) {
    .mute {
      height: 280px;
      width: 290px;
      font-size: 24px;
    }
  }
  @media screen and (max-width: 575px) {
    .mute {
      height: 240px;
      width: 240px;
      font-size: 20px;
      line-height: 25px;
    }
  }

  @media screen and (max-width: 480px) {
    .mute {
      height: 200px;

      font-size: 20px;
      line-height: 25px;
    }
  }

  @media screen and (min-width: 992px) {
    .mute {
      zoom: 0.7;
    }
  }

  @media screen and (min-width: 1200px) {
    .mute {
      zoom: 0.8;
    }
  }

  @media screen and (min-width: 1400px) {
    .mute {
      zoom: 1;
    }
  }

  @media screen and (min-width: 2160px) {
    .max-width {
      max-width: 1800px;
      margin: 0 auto;
    }
  }
`;

export const BannerThanks = styled.div`
  background: linear-gradient(6deg, #4d6af6 0%, #728ff7 100%);
  min-height: 100vh;

  h1 {
    color: #fff;
    font-family: 'Quicksand';
    font-size: 46px;
    font-weight: 700;
    line-height: 45px;
  }

  h2 {
    color: #fff;
    text-align: center;
    font-family: 'Archivo';
    font-size: 22px;
    font-weight: 300;
    line-height: 35px;

    span {
      font-weight: 700;
    }
  }

  h3 {
    color: #ffe896;
    text-align: center;
    font-family: 'Archivo';
    font-size: 22px;
    font-weight: 400;
    line-height: 35px;
  }

  .bg-form {
    border-radius: 25px;
    background: #fffdfd;
    box-shadow: 0px 67px 70px 0px rgba(0, 0, 0, 0.12);
    padding: 43px 40px;
  }

  .bg-form h2 {
    color: #116bff;
    font-family: 'Archivo';
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 45.5px;
    span {
      display: block;
      font-size: 28px;
      font-weight: 400;
    }
  }

  .bg-form p {
    color: #021027;
    text-align: center;
    font-family: 'Archivo';
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 35px;
  }

  .bg-form button {
    width: 100%;
    display: block;
    text-align: center;
    border-radius: 18px;
    background: #116bff;
    padding: 20.5px 0px;
    color: #fff;
    font-family: 'Archivo';
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    transition-duration: 0.3s;
    :hover {
      opacity: 0.8;
    }
  }

  .depoiment {
    img {
      width: 74px;
      height: 74px;
      border: 1px solid rgba(255, 255, 255, 0.5);
      border-radius: 28px;
    }
    p {
      color: #fff;
      font-family: 'Quicksand';
      font-size: 20px;
      font-weight: 600;
      line-height: 28px;
      margin-bottom: 0px;
      margin-top: 10px;
    }
    span {
      display: block;
      color: #fff;
      font-family: 'Source Sans Pro';
      font-size: 16px;
      font-weight: 300;
      line-height: 18px;
    }
  }

  .text-depoiment {
    p {
      color: #fff;
      font-family: 'Quicksand';
      font-size: 28px;
      font-weight: 700;
      line-height: 32px;
      span {
        font-size: 60px;

        font-family: 'Source Sans Pro';
      }
    }
    span.text {
      display: block;
      color: #fff;
      font-family: 'Source Sans Pro';
      font-size: 18px;
      font-weight: 400;
      line-height: 28px;
      padding-left: 32px;
    }
  }

  .email-folder {
    p {
      color: #585656;
      font-family: 'Archivo';
      font-size: 14px;
      font-style: normal;
      font-weight: 300;
      line-height: 18px;
      text-align: start;
    }

    .email {
      font-weight: 500;
    }

    .check-folder {
      color: #116bff;
      font-weight: 600;
    }
  }

  @media screen and (max-width: 575px) {
    h1 {
      font-size: 36px;
    }

    h2 {
      font-size: 20px;
    }

    .bg-form {
      padding: 40px 20px;
    }

    .bg-form h2 {
      font-size: 34px;
    }

    .bg-form h2 span {
      font-size: 24px;
    }

    .bg-form p {
      font-size: 20px;
    }

    .depoiment {
      img {
        width: 72px;
        height: 72px;
      }
      p {
        font-size: 10px;
        line-height: 8px;
      }

      span {
        font-size: 8px;
      }
    }

    .text-depoiment {
      p {
        font-size: 16px;
        line-height: 18px;
        span {
          font-size: 16px;
        }
      }
      span.text {
        font-size: 13px;
        font-weight: 300;
        line-height: 15px;
        padding-left: 0px;
      }
    }

    .email-folder {
      p {
        color: #585656;
        font-family: 'Archivo';
        font-size: 14px;
        font-style: normal;
        font-weight: 300;
        line-height: 18px;
        text-align: center;
      }

      .email {
        font-weight: 500;
      }

      .check-folder {
        color: #116bff;
        font-weight: 600;
      }
    }
  }
`;

export const Modal = styled(ModalComponent)`
  background: #00000055;

  h2 {
    color: #116bff;
    text-align: center;
    font-family: 'Archivo';
    font-size: 36px;
    font-weight: 700;
    line-height: 38px;
    margin: 41px 0px;
  }

  h3 {
    color: #116bff;
    text-align: center;
    font-family: 'Archivo';
    font-size: 32px;
    font-weight: 500;
    line-height: 38px;
  }

  input {
    height: 50px;
    color: #021027;
    font-family: 'Inter';
    font-size: 14px;
    font-weight: 500;
    line-height: 134.023%;

    ::placeholder {
      color: #ababab;
    }
  }

  .bd-input {
    border-radius: 18px;
    border: 1px solid #dedede;
    background: #fff;
    height: 65px;
    padding: 5px 25px;
  }

  .modal-content {
    border: 7px solid #116bff !important;
    box-shadow: 0px 4px 90px 0px rgba(17, 107, 255, 0.5);
  }

  .body {
    padding: 0rem 40px 1rem 40px;
  }

  .input-button {
    background: transparent !important;
    border: none !important;
    height: 30px !important;
    top: 17px !important;
    left: 17px !important;
    transform: scale(1.5) !important;
  }

  .btn-submit {
    width: 100%;
    border-radius: 18px;
    background: #116bff;
    color: #fff;
    font-family: 'Archivo';
    font-size: 22px;
    font-weight: 600;
    line-height: normal;
    padding: 20px;
    transition-duration: 0.2s;
    :hover {
      opacity: 0.8;
    }
  }

  .modal-content {
    border-radius: 25px;
    background: #fffdfd;
    border: none;
  }

  .progress {
    height: 100%;
    padding: 0px;
    background: transparent;
    border-radius: 18px;
    border: 1px solid #dedede;
  }

  .progress-bar {
    font-size: 18px;
    font-weight: 700;
    height: 55px;
    border-radius: 18px 0 0 18px;
    transition: 0.4s linear;
    transition-property: width, background-color;
    span {
      font-family: 'Inter';
      text-align: left;
      padding-left: 15px;
      position: relative;
      z-index: 10;
    }
  }

  .progress-striped .progress-bar {
    overflow: hidden;
    background-color: rgba(17, 107, 255, 1);
    width: 80%;
    animation: progressAnimationStrike 3s;
  }

  .progress-animation {
    height: 55px;
    width: 1000px;
    background-image: linear-gradient(
      135deg,
      rgb(20, 80, 245) 5%,
      transparent 5%,
      transparent 10%,
      rgb(20, 80, 245) 10%,
      rgb(20, 80, 245) 15%,
      transparent 15%,
      transparent 20%,
      rgb(20, 80, 245) 20%,
      rgb(20, 80, 245) 25%,
      transparent 25%,
      transparent 30%,
      rgb(20, 80, 245) 30%,
      rgb(20, 80, 245) 35%,
      transparent 35%,
      transparent 40%,
      rgb(20, 80, 245) 40%,
      rgb(20, 80, 245) 45%,
      transparent 45%,
      transparent 50%,
      rgb(20, 80, 245) 50%,
      rgb(20, 80, 245) 55%,
      transparent 55%,
      transparent 60%,
      rgb(20, 80, 245) 60%,
      rgb(20, 80, 245) 65%,
      transparent 65%,
      transparent 70%,
      rgb(20, 80, 245) 70%,
      rgb(20, 80, 245) 75%,
      transparent 75%,
      transparent 80%,
      rgb(20, 80, 245) 80%,
      rgb(20, 80, 245) 85%,
      transparent 85%,
      transparent 90%,
      rgb(20, 80, 245) 90%,
      rgb(20, 80, 245) 95%,
      transparent 95%,
      transparent
    );
    animation: progressAnimationBar 2s infinite;
  }

  @keyframes progressAnimationStrike {
    from {
      width: 0;
    }
    to {
      width: 80%;
    }
  }

  @keyframes progressAnimationBar {
    from {
      right: -500px;
    }
    to {
      right: 0px;
    }
  }

  @media screen and (max-width: 991px) {
    h2 {
      font-size: 34px;
      color: #ff1a50;
    }
    h3 {
      font-size: 28px;
    }
  }

  @media screen and (max-width: 500px) {
    h2 {
      font-size: 32px;
    }
    .body {
      padding: 0rem 25px 1rem 25px;
    }

    .modal-content {
      border: 4px solid #116bff !important;
    }
  }

  @media screen and (max-width: 400px) {
    h2 {
      font-size: 23px;
      line-height: 30px;
      margin: 26px 0px;
    }
    h3 {
      font-size: 22px;
    }
  }
`;
