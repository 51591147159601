import styled from 'styled-components';

export const Container = styled.div`
  background: #f4f4f4;

  .btn-avatar-wallet {
    background: transparent !important;
    border-radius: 30px;
  }

  .title {
    font-weight: 600;
    font-size: 30px;
    line-height: 36px;
    color: #ffffff;
  }

  .bg-values span {
    min-width: 100px;
  }

  .chat button {
    cursor: pointer;
    border: none;

    background: transparent;
    background-origin: border-box !important;

    border-image-slice: 1 !important;

    transition-duration: 0.3s;
    font-weight: 500;
    font-size: 20px;
    line-height: 22px;
    color: #777;
    span {
      padding: 5px 0px;
      display: block;
    }
    :hover {
      color: #021027;
    }
  }

  .chat-active {
    color: #021027 !important;
  }

  .number-participants {
    color: #116bff;

    font-size: 18px;
    font-family: 'Poppins';

    font-weight: 500;
    padding: 4px 9px;

    border-radius: 8px;
    border: 1px solid #116bff;
  }

  .messages img {
    height: 26px;
    width: 26px;
    border-radius: 26px;
    border: 1px solid rgba(228, 228, 228, 0.5);
    background: #303030;
  }

  .messages .no-avatar {
    min-height: 26px;
    min-width: 26px;
  }

  .messages p {
    color: #fff;
    font-size: 12px;
    font-family: 'Inter';
    font-weight: 500;
    line-height: 131.119%;
    margin-bottom: 0px;
    text-align: start;
  }

  .name-chat {
    font-size: 12px;
    font-family: 'Inter';
    font-weight: 500;
  }

  .name-you {
    color: #021027;
  }

  .name-participants {
    color: #514f4f;
  }

  .hour {
    color: #bfbfbf;
    font-size: 12px;
  }

  .bg-participants {
    background: #777;
    backdrop-filter: blur(2px);
    border-radius: 0px 10px 10px 10px;
    p {
      color: #fff !important;
    }
  }

  .bg-you {
    background: #116bff;
    backdrop-filter: blur(2px);
    border-radius: 10px 0px 10px 10px;
    p {
      color: #fff !important;
    }
  }

  .bg-you-attendees {
    background: #116bff;
    border-radius: 50px;
    span.name {
      color: #fff;
      font-size: 12px;
      font-family: 'Inter';

      font-weight: 500;
    }
    span.type {
      color: #fff;

      font-size: 10px;
      font-family: 'Inter';

      font-weight: 300;
    }
  }

  .bg-attendees {
    background: #fff;
    border-radius: 50px;
    span.name {
      color: #3a3a3a;
      font-size: 12px;
      font-family: 'Inter';

      font-weight: 500;
    }
    span.type {
      color: #777;

      font-size: 10px;
      font-family: 'Inter';

      font-weight: 300;
    }
  }

  .emojis-mobile {
    position: absolute;

    bottom: 160px;
  }

  .vh-67 {
    height: calc(100vh - 280px);
    transition: all 0.3s ease;
    scroll-behavior: smooth;

    ::-webkit-scrollbar-thumb {
      background-color: #e0e0e0;
      border-radius: 4px;
    }
  }
  .vh-32 {
    height: 32vh;
    transition: all 0.3s ease;

    ::-webkit-scrollbar-thumb {
      background-color: #e0e0e0;
      border-radius: 4px;
    }
  }

  .bg-input {
    background: #fff;
    //box-shadow: 0px 14px 28px rgba(0, 0, 0, 0.25);
    border-radius: 12px;
  }

  .bg-input input {
    color: #000 !important;
    font-size: 14px;
  }

  .bg-input input::placeholder {
    color: #000 !important;
  }

  button.bg-input {
    background: #fff;
    border-radius: 50%;
    border: 2px solid #116bff;
    width: 45px;
    height: 45px;
  }

  .bg-input button {
    background: #116bff;
    border-radius: 8px;
    width: 49px;
  }

  aside.EmojiPickerReact.epr-main {
    transition: all 0.3s ease;
    border-style: none;
    border-width: none;
    background: rgba(24, 25, 26, 0.85);
    box-shadow: 0px 14px 28px rgb(0 0 0 / 25%);
    border-radius: 12px;
  }

  .EmojiPickerReact li.epr-emoji-category > .epr-emoji-category-label {
    background-color: rgba(24, 25, 26, 0.85);
  }

  @media screen and (max-width: 991px) {
    .emojis-mobile {
      position: absolute;
      width: 100%;
      bottom: 100px;
    }
    .vh-67 {
      height: 350px;
    }
    .vh-32 {
      height: 350px;
    }

    .messages img {
      height: 32px;
      width: 32px;
    }

    .bg-attendees span.name,
    .bg-you-attendees span.name {
      font-size: 14px;
    }

    .bg-attendees span.type,
    .bg-you-attendees span.type {
      font-size: 12px;
    }
  }

  @media screen and (max-width: 360px) {
    .bg-input input {
      width: 100%;
    }
  }
`;
