import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { FaExclamationCircle } from 'react-icons/fa';
import Lottie from 'react-lottie';
import { BsDash, BsPlus } from 'react-icons/bs';
import { useHistory, useLocation } from 'react-router-dom';

import { ScrollParallax } from 'react-just-parallax';
import { Helmet } from 'react-helmet';
import { useResize } from '~/hooks/Resize';

import {
  Container,
  Attention,
  Gpt,
  Converting,
  Breakthrough,
  Perfect,
  Easy,
  Unlimited,
  All,
  Bonus,
  Questions,
  Current,
  Modal,
} from './styles';
import Player from '~/components/Player';
import Payment from '~/components/Payment';

import thumbFree from '~/assets/defaults/thumb-free.webp';
import thumbHand from '~/assets/defaults/thumb-hand.png';
import papperTop from '~/assets/defaults/papper-bonus.png';
import trafficIcon from '~/assets/defaults/traffic-icon.png';
import mastering from '~/assets/defaults/traffic-text.svg';
import cards from '~/assets/defaults/cards.png';
import getAll from '~/assets/defaults/get-all.png';
import moneyBack from '~/assets/defaults/money-back.png';
import messages1 from '~/assets/defaults/messages_1.png';
import messages2 from '~/assets/defaults/messages_2.png';
import devices from '~/assets/defaults/devices-guide.png';
import devicesMob from '~/assets/defaults/devices-guide-mob-new.png';
import table1 from '~/assets/defaults/table-1.png';
import table1Mob from '~/assets/defaults/table-1-mob.png';
import table2 from '~/assets/defaults/table-2.png';
import table2Mob from '~/assets/defaults/table-2-mob.png';
import powerful from '~/assets/defaults/powerful.png';
import all1 from '~/assets/defaults/all-1.png';
import all2 from '~/assets/defaults/all-2.png';
import all3 from '~/assets/defaults/all-3.png';
import all4 from '~/assets/defaults/all-4.png';
import all5 from '~/assets/defaults/all-5.png';
import all6 from '~/assets/defaults/all-6.png';
import all7 from '~/assets/defaults/all-7.png';
import all8 from '~/assets/defaults/all-8.png';
import all9 from '~/assets/defaults/all-9.png';
import all10 from '~/assets/defaults/all-10.png';
import all11 from '~/assets/defaults/all-11.png';
import all12 from '~/assets/defaults/all-12.png';
import all13 from '~/assets/defaults/all-13.png';
import all14 from '~/assets/defaults/all-14.png';
import all15 from '~/assets/defaults/all-15.png';
import all16 from '~/assets/defaults/all-16.png';
import all17 from '~/assets/defaults/all-17.png';
import all18 from '~/assets/defaults/all-18.png';
import all19 from '~/assets/defaults/all-19.png';
import all20 from '~/assets/defaults/all-20.png';
import all21 from '~/assets/defaults/all-21.png';
import all22 from '~/assets/defaults/all-22.png';
import all23 from '~/assets/defaults/all-23.png';
import all24 from '~/assets/defaults/all-24.png';
import all25 from '~/assets/defaults/all-25.png';
import bonus1 from '~/assets/defaults/bonus-1.png';
import bonus2 from '~/assets/defaults/bonus-2.png';
import bonus3 from '~/assets/defaults/bonus-3.png';
import current from '~/assets/defaults/current.png';
import circle from '~/assets/defaults/circle-title-ultimate.png';
import hand from '~/assets/defaults/device-guide-hero.png';
import checkBlue from '~/assets/animations/check-blue.json';
import checkGreen from '~/assets/animations/check-green.json';
import btnDownload from '~/assets/animations/btn-download.json';
import ArrowDown from '~/assets/animations/arrow-down.json';
import ArrowYellow from '~/assets/animations/arrow-yellow.json';
import plus from '~/assets/animations/plus.json';
import chatGptMastery from '~/assets/defaults/chat-gpt-mastery-new.png';
import muteImg from '~/assets/icons/mute.svg';
import waves from '~/assets/animations/waves-sound.json';
import ModalEnterEmail from '~/components/ModalEnterEmail';
import Logos from './Logos';

import facebook from '~/assets/icons/facebook-hero.svg';
import instagram from '~/assets/icons/instagram-hero.svg';
import x from '~/assets/icons/x-hero.svg';
import youtube from '~/assets/icons/youtube-hero.svg';
import threads from '~/assets/icons/threads-hero.svg';
import avatar1 from '~/assets/defaults/image-2.png';
import avatar2 from '~/assets/defaults/image-3.png';
import avatar3 from '~/assets/defaults/image-4.png';
import robot from '~/assets/defaults/robot.png';
import robotMob from '~/assets/defaults/robot-mobile1.png';
import DisclaimerNew from '~/components/DisclaimerNew';

interface ILocation {
  from: Location;
}

const UltimateGuide: React.FC = () => {
  const history = useHistory();
  const location = useLocation<ILocation>();
  const { width } = useResize();
  const [answer, setAnswer] = useState('');
  const [show, setShow] = useState(false);
  const [mute, setMute] = useState(true);
  const [showModalNo, setShowModalNo] = useState(false);
  const [video, setVideo] = useState('');

  useEffect(() => {
    window.clickmagick_cmc.log('a', 'optin', 'AutoAffiliate');
  }, []);

  const handleClickQuestion = useCallback(
    (data) => {
      if (answer === data) {
        setAnswer('');
      } else {
        setAnswer(data);
      }
    },
    [answer]
  );

  const handleClick = useCallback(() => {
    const btnModalEnterEmail = document.getElementById('enterEmail');
    if (btnModalEnterEmail) {
      btnModalEnterEmail.click();
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>
          AI Content Mastery: The Ultimate Guide to Revolutionize Your Social
          Media
        </title>
        <meta
          name="title"
          content="AI Content Mastery: The Ultimate Guide to Revolutionize Your Social Media"
        />
        <meta
          name="description"
          content="AI Content Mastery: The Ultimate Guide to Revolutionize Your Social Media"
        />
        <meta
          property="og:url"
          content="https://autoaffiliate.ai/ultimate-guide"
        />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="AUTOAFFILIATE" />
        <meta
          property="og:description"
          content="Supercharge your social media with AI! Our Ultimate Guide offers expert prompts and AI insights to revolutionize your content creation. Download now to transform your strategy!"
        />
        <meta
          property="og:image"
          content="https://cdn.autoaffiliate.ai/files/metatag-ultimate-guide.png"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta property="twitter:domain" content="autoaffiliate.ai" />
        <meta
          property="twitter:url"
          content="https://autoaffiliate.ai/ultimate-guide"
        />
        <meta name="twitter:title" content="AUTOAFFILIATE" />
        <meta
          name="twitter:description"
          content="Supercharge your social media with AI! Our Ultimate Guide offers expert prompts and AI insights to revolutionize your content creation. Download now to transform your strategy!"
        />
      </Helmet>
      <Container>
        <Attention className="bg-danger">
          <div className="container zoom">
            <div className="row">
              <div className="col zoom">
                <h2 className="h6 h5-sm h4-xl text-white mb-0 py-3 text-center">
                  <FaExclamationCircle />
                  <b className="ms-3">ATTENTION: </b>
                  Limited Time Offer - <b className="">100% Off</b>
                </h2>
              </div>
            </div>
          </div>
        </Attention>
        <Gpt>
          <div className="container zoom">
            <div className="row justify-content-center">
              <div className="col-12 px-lg-5">
                <h1 className="text-center my-4">
                  Unlock <span className="cl-blue">Unlimited Content</span>{' '}
                  Potential with AI –{' '}
                  <span className="cl-blue">
                    The{' '}
                    <span className="circle-title text-black">
                      <span>Only&nbsp;Guide</span>
                      <img src={circle} alt="Circle" />
                    </span>{' '}
                    You’ll
                  </span>{' '}
                  <span className="cl-blue">Ever Need!</span>
                </h1>
                <h3 className="text-center px-lg-5">
                  <b className="fw-bold">We’ve invested thousands of dollars</b>{' '}
                  in this research{' '}
                  <b className="fw-bold">so you don’t have to</b>. It’s the only
                  guide you’ll ever need to master content creation,{' '}
                  <b className="fw-bold">guaranteed!</b>
                </h3>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-8 pe-lg-0 mt-4">
                <h5 className="text-center text-lg-start ps-lg-4">You Get:</h5>

                <div className="my-2 pe-none d-flex align-items-center lootie">
                  <Lottie
                    options={{
                      animationData: checkBlue,
                      autoplay: true,
                      loop: true,
                      rendererSettings: {
                        preserveAspectRatio: 'xMidYMid slice',
                      },
                    }}
                    height={39}
                    width={39}
                  />
                  <h4 className="mb-0">
                    131+ Dynamic Content Prompts (1,000’s of Combos)
                  </h4>
                </div>
                <div className="d-lg-flex">
                  <div className="">
                    <div className="my-2 pe-none d-flex align-items-center lootie">
                      <Lottie
                        options={{
                          animationData: checkBlue,
                          autoplay: true,
                          loop: true,
                          rendererSettings: {
                            preserveAspectRatio: 'xMidYMid slice',
                          },
                        }}
                        height={39}
                        width={39}
                      />
                      <h4 className="mb-0">3500+ Assorted Marketing Prompts</h4>
                    </div>

                    <div className="my-2 pe-none d-flex align-items-center lootie ">
                      <Lottie
                        options={{
                          animationData: checkBlue,
                          autoplay: true,
                          loop: true,
                          rendererSettings: {
                            preserveAspectRatio: 'xMidYMid slice',
                          },
                        }}
                        height={39}
                        width={39}
                      />
                      <h4 className="mb-0">364 Pages of Valuable Content</h4>
                    </div>

                    <div className="my-2 pe-none d-flex align-items-center lootie">
                      <Lottie
                        options={{
                          animationData: checkBlue,
                          autoplay: true,
                          loop: true,
                          rendererSettings: {
                            preserveAspectRatio: 'xMidYMid slice',
                          },
                        }}
                        height={39}
                        width={39}
                      />
                      <h4 className="mb-0">
                        Customizable for Any Niche in Seconds
                      </h4>
                    </div>
                  </div>
                  <div className="">
                    <div className="my-2 pe-none d-flex align-items-center lootie ">
                      <Lottie
                        options={{
                          animationData: checkBlue,
                          autoplay: true,
                          loop: true,
                          rendererSettings: {
                            preserveAspectRatio: 'xMidYMid slice',
                          },
                        }}
                        height={39}
                        width={39}
                      />
                      <h4 className="mb-0">4 Bonus Manuals</h4>
                    </div>

                    <div className="my-2 pe-none d-flex align-items-center lootie ">
                      <Lottie
                        options={{
                          animationData: checkBlue,
                          autoplay: true,
                          loop: true,
                          rendererSettings: {
                            preserveAspectRatio: 'xMidYMid slice',
                          },
                        }}
                        height={39}
                        width={39}
                      />
                      <h4 className="mb-0">
                        How to Use AI for Content Creation
                      </h4>
                    </div>
                  </div>
                </div>
                <div className="d-lg-inline-block">
                  <h2 className="my-3 my-lg-4 font-small text-center text-lg-start">
                    All of this for <span>$97</span> <span>FREE</span>
                  </h2>
                  <div className="mb-5 mb-lg-0">
                    <button
                      type="button"
                      className="w-100 btn-download-hero"
                      onClick={handleClick}
                    >
                      <Lottie
                        options={{
                          animationData: btnDownload,
                          autoplay: true,
                          loop: true,
                          rendererSettings: {
                            preserveAspectRatio: 'xMidYMid slice',
                          },
                        }}
                        height={90}
                        width={90}
                      />
                      <span>
                        Download Now
                        <br />
                        <span>...& Get Instant Access</span>
                      </span>
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 mt-4 text-center text-lg-start">
                <img src={hand} alt="Hand" className="img-hero" />
              </div>
            </div>
          </div>
        </Gpt>

        <Logos className="mb-5" />

        <Converting>
          <div className="container zoom">
            <div className="row justify-content-center">
              <div className="col-12">
                <h2 className="my-5 py-5">
                  <span>Instantly Unleash Content That Attracts</span>{' '}
                  Followers, Likes, and Comments on Steroids Skyrocketing Your
                  Online Sales!
                </h2>
              </div>
              <div className="col-lg-11">
                <div className="row">
                  <div className="col-6 col-lg-3 px-lg-2">
                    <div className="pe-none d-flex align-items-center lootie mb-3">
                      <Lottie
                        options={{
                          animationData: checkGreen,
                          autoplay: true,
                          loop: true,
                          rendererSettings: {
                            preserveAspectRatio: 'xMidYMid slice',
                          },
                        }}
                        height={39}
                        width={39}
                      />
                      <h3 className="mb-0">Facebook</h3>
                    </div>
                    <div className="pe-none d-flex align-items-center lootie my-3">
                      <Lottie
                        options={{
                          animationData: checkGreen,
                          autoplay: true,
                          loop: true,
                          rendererSettings: {
                            preserveAspectRatio: 'xMidYMid slice',
                          },
                        }}
                        height={39}
                        width={39}
                      />
                      <h3 className="mb-0">Instagram</h3>
                    </div>
                    <div className="pe-none d-flex align-items-center lootie my-3">
                      <Lottie
                        options={{
                          animationData: checkGreen,
                          autoplay: true,
                          loop: true,
                          rendererSettings: {
                            preserveAspectRatio: 'xMidYMid slice',
                          },
                        }}
                        height={39}
                        width={39}
                      />
                      <h3 className="mb-0">Twitter (X)</h3>
                    </div>
                    <div className="pe-none d-flex align-items-center lootie mt-3">
                      <Lottie
                        options={{
                          animationData: checkGreen,
                          autoplay: true,
                          loop: true,
                          rendererSettings: {
                            preserveAspectRatio: 'xMidYMid slice',
                          },
                        }}
                        height={39}
                        width={39}
                      />
                      <h3 className="mb-0">TikTok</h3>
                    </div>
                  </div>
                  <div className="col-6 col-lg-3 ps-0 ps-sm-2 px-lg-2">
                    <div className="pe-none d-flex align-items-center lootie mb-3">
                      <Lottie
                        options={{
                          animationData: checkGreen,
                          autoplay: true,
                          loop: true,
                          rendererSettings: {
                            preserveAspectRatio: 'xMidYMid slice',
                          },
                        }}
                        height={39}
                        width={39}
                      />
                      <h3 className="mb-0">Threads</h3>
                    </div>
                    <div className="pe-none d-flex align-items-center lootie my-3">
                      <Lottie
                        options={{
                          animationData: checkGreen,
                          autoplay: true,
                          loop: true,
                          rendererSettings: {
                            preserveAspectRatio: 'xMidYMid slice',
                          },
                        }}
                        height={39}
                        width={39}
                      />
                      <h3 className="mb-0">YouTube</h3>
                    </div>
                    <div className="pe-none d-flex align-items-center lootie my-3">
                      <Lottie
                        options={{
                          animationData: checkGreen,
                          autoplay: true,
                          loop: true,
                          rendererSettings: {
                            preserveAspectRatio: 'xMidYMid slice',
                          },
                        }}
                        height={39}
                        width={39}
                      />
                      <h3 className="mb-0">Short Form</h3>
                    </div>
                    <div className="pe-none d-flex align-items-center lootie mt-3">
                      <Lottie
                        options={{
                          animationData: checkGreen,
                          autoplay: true,
                          loop: true,
                          rendererSettings: {
                            preserveAspectRatio: 'xMidYMid slice',
                          },
                        }}
                        height={39}
                        width={39}
                      />
                      <h3 className="mb-0">Quotes</h3>
                    </div>
                  </div>
                  <div className="col-6 col-lg-3 px-lg-2">
                    <div className="pe-none d-flex align-items-center lootie my-3 mt-lg-0">
                      <Lottie
                        options={{
                          animationData: checkGreen,
                          autoplay: true,
                          loop: true,
                          rendererSettings: {
                            preserveAspectRatio: 'xMidYMid slice',
                          },
                        }}
                        height={39}
                        width={39}
                      />
                      <h3 className="mb-0">Long Form</h3>
                    </div>
                    <div className="pe-none d-flex align-items-center lootie my-3">
                      <Lottie
                        options={{
                          animationData: checkGreen,
                          autoplay: true,
                          loop: true,
                          rendererSettings: {
                            preserveAspectRatio: 'xMidYMid slice',
                          },
                        }}
                        height={39}
                        width={39}
                      />
                      <h3 className="mb-0">100% Unique</h3>
                    </div>
                    <div className="pe-none d-flex align-items-center lootie my-3">
                      <Lottie
                        options={{
                          animationData: checkGreen,
                          autoplay: true,
                          loop: true,
                          rendererSettings: {
                            preserveAspectRatio: 'xMidYMid slice',
                          },
                        }}
                        height={39}
                        width={39}
                      />
                      <h3 className="mb-0">Videos</h3>
                    </div>
                    <div className="pe-none d-flex align-items-center lootie mt-3">
                      <Lottie
                        options={{
                          animationData: checkGreen,
                          autoplay: true,
                          loop: true,
                          rendererSettings: {
                            preserveAspectRatio: 'xMidYMid slice',
                          },
                        }}
                        height={39}
                        width={39}
                      />
                      <h3 className="mb-0">Images</h3>
                    </div>
                  </div>
                  <div className="col-6 col-lg-3 ps-0 ps-sm-2 px-lg-2">
                    <div className="pe-none d-flex align-items-center lootie my-3 mt-lg-0">
                      <Lottie
                        options={{
                          animationData: checkGreen,
                          autoplay: true,
                          loop: true,
                          rendererSettings: {
                            preserveAspectRatio: 'xMidYMid slice',
                          },
                        }}
                        height={39}
                        width={39}
                      />
                      <h3 className="mb-0">All AI Driven</h3>
                    </div>
                    <div className="pe-none d-flex align-items-center lootie my-3">
                      <Lottie
                        options={{
                          animationData: checkGreen,
                          autoplay: true,
                          loop: true,
                          rendererSettings: {
                            preserveAspectRatio: 'xMidYMid slice',
                          },
                        }}
                        height={39}
                        width={39}
                      />
                      <h3 className="mb-0">Fully Customizable</h3>
                    </div>
                    <div className="pe-none d-flex align-items-center lootie my-3">
                      <Lottie
                        options={{
                          animationData: checkGreen,
                          autoplay: true,
                          loop: true,
                          rendererSettings: {
                            preserveAspectRatio: 'xMidYMid slice',
                          },
                        }}
                        height={39}
                        width={39}
                      />
                      <h3 className="mb-0">Any Niche</h3>
                    </div>
                    <div className="pe-none d-flex align-items-center lootie mt-3">
                      <Lottie
                        options={{
                          animationData: checkGreen,
                          autoplay: true,
                          loop: true,
                          rendererSettings: {
                            preserveAspectRatio: 'xMidYMid slice',
                          },
                        }}
                        height={39}
                        width={39}
                      />
                      <h3 className="mb-0">...PLUS MORE!</h3>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-11">
                <hr className="line" />
              </div>
            </div>
          </div>
        </Converting>

        <Perfect>
          <div className="container zoom">
            <div className="row justify-content-center">
              <div className="col-12">
                <h2 className="mb-5 pb-5 px-4 px-md-0">
                  The Ultimate Guide To Creating Magnetic Social Content{' '}
                  <span className="fw-bold">is perfect for...</span>
                </h2>
              </div>
              <div className="col-lg-11 ps-5 ps-md-0">
                <div className="row">
                  {/* para mobile somente */}
                  <div className="col-md-6 d-lg-none">
                    <div className="pe-none d-flex align-items-center lootie mb-3">
                      <Lottie
                        options={{
                          animationData: checkBlue,
                          autoplay: true,
                          loop: true,
                          rendererSettings: {
                            preserveAspectRatio: 'xMidYMid slice',
                          },
                        }}
                        height={39}
                        width={39}
                      />
                      <h3 className="mb-0">Course Creators</h3>
                    </div>
                    <div className="pe-none d-flex align-items-center lootie my-3">
                      <Lottie
                        options={{
                          animationData: checkBlue,
                          autoplay: true,
                          loop: true,
                          rendererSettings: {
                            preserveAspectRatio: 'xMidYMid slice',
                          },
                        }}
                        height={39}
                        width={39}
                      />
                      <h3 className="mb-0">Coaches & Consultants</h3>
                    </div>
                    <div className="pe-none d-flex align-items-center lootie my-3">
                      <Lottie
                        options={{
                          animationData: checkBlue,
                          autoplay: true,
                          loop: true,
                          rendererSettings: {
                            preserveAspectRatio: 'xMidYMid slice',
                          },
                        }}
                        height={39}
                        width={39}
                      />
                      <h3 className="mb-0">Network Marketers</h3>
                    </div>
                    <div className="pe-none d-flex align-items-center lootie my-3">
                      <Lottie
                        options={{
                          animationData: checkBlue,
                          autoplay: true,
                          loop: true,
                          rendererSettings: {
                            preserveAspectRatio: 'xMidYMid slice',
                          },
                        }}
                        height={39}
                        width={39}
                      />
                      <h3 className="mb-0">Digital Product Sellers</h3>
                    </div>
                    <div className="pe-none d-flex align-items-center lootie my-3">
                      <Lottie
                        options={{
                          animationData: checkBlue,
                          autoplay: true,
                          loop: true,
                          rendererSettings: {
                            preserveAspectRatio: 'xMidYMid slice',
                          },
                        }}
                        height={39}
                        width={39}
                      />
                      <h3 className="mb-0">Speakers & Experts</h3>
                    </div>
                    <div className="pe-none d-flex align-items-center lootie my-3">
                      <Lottie
                        options={{
                          animationData: checkBlue,
                          autoplay: true,
                          loop: true,
                          rendererSettings: {
                            preserveAspectRatio: 'xMidYMid slice',
                          },
                        }}
                        height={39}
                        width={39}
                      />
                      <h3 className="mb-0">Authors & Bloggers</h3>
                    </div>
                    <div className="pe-none d-flex align-items-center lootie mb-3">
                      <Lottie
                        options={{
                          animationData: checkBlue,
                          autoplay: true,
                          loop: true,
                          rendererSettings: {
                            preserveAspectRatio: 'xMidYMid slice',
                          },
                        }}
                        height={39}
                        width={39}
                      />
                      <h3 className="mb-0">Ecommerce Stores</h3>
                    </div>
                    <div className="pe-none d-flex align-items-center lootie my-3">
                      <Lottie
                        options={{
                          animationData: checkBlue,
                          autoplay: true,
                          loop: true,
                          rendererSettings: {
                            preserveAspectRatio: 'xMidYMid slice',
                          },
                        }}
                        height={39}
                        width={39}
                      />
                      <h3 className="mb-0">Service Businesses</h3>
                    </div>
                    <div className="pe-none d-flex align-items-center lootie my-3">
                      <Lottie
                        options={{
                          animationData: checkBlue,
                          autoplay: true,
                          loop: true,
                          rendererSettings: {
                            preserveAspectRatio: 'xMidYMid slice',
                          },
                        }}
                        height={39}
                        width={39}
                      />
                      <h3 className="mb-0">B2B Companies</h3>
                    </div>
                  </div>

                  <div className="col-md-6 d-lg-none">
                    <div className="pe-none d-flex align-items-center lootie mb-3">
                      <Lottie
                        options={{
                          animationData: checkBlue,
                          autoplay: true,
                          loop: true,
                          rendererSettings: {
                            preserveAspectRatio: 'xMidYMid slice',
                          },
                        }}
                        height={39}
                        width={39}
                      />
                      <h3 className="mb-0">Local Stores</h3>
                    </div>
                    <div className="pe-none d-flex align-items-center lootie my-3">
                      <Lottie
                        options={{
                          animationData: checkBlue,
                          autoplay: true,
                          loop: true,
                          rendererSettings: {
                            preserveAspectRatio: 'xMidYMid slice',
                          },
                        }}
                        height={39}
                        width={39}
                      />
                      <h3 className="mb-0">Professional Services</h3>
                    </div>
                    <div className="pe-none d-flex align-items-center lootie my-3">
                      <Lottie
                        options={{
                          animationData: checkBlue,
                          autoplay: true,
                          loop: true,
                          rendererSettings: {
                            preserveAspectRatio: 'xMidYMid slice',
                          },
                        }}
                        height={39}
                        width={39}
                      />
                      <h3 className="mb-0">Contractors</h3>
                    </div>
                    <div className="pe-none d-flex align-items-center lootie mb-3">
                      <Lottie
                        options={{
                          animationData: checkBlue,
                          autoplay: true,
                          loop: true,
                          rendererSettings: {
                            preserveAspectRatio: 'xMidYMid slice',
                          },
                        }}
                        height={39}
                        width={39}
                      />
                      <h3 className="mb-0">Agencies & Freelancers</h3>
                    </div>
                    <div className="pe-none d-flex align-items-center lootie my-3">
                      <Lottie
                        options={{
                          animationData: checkBlue,
                          autoplay: true,
                          loop: true,
                          rendererSettings: {
                            preserveAspectRatio: 'xMidYMid slice',
                          },
                        }}
                        height={39}
                        width={39}
                      />
                      <h3 className="mb-0">Digital Marketers</h3>
                    </div>
                    <div className="pe-none d-flex align-items-center lootie my-3">
                      <Lottie
                        options={{
                          animationData: checkBlue,
                          autoplay: true,
                          loop: true,
                          rendererSettings: {
                            preserveAspectRatio: 'xMidYMid slice',
                          },
                        }}
                        height={39}
                        width={39}
                      />
                      <h3 className="mb-0">Copywriters</h3>
                    </div>
                    <div className="pe-none d-flex align-items-center lootie my-3">
                      <Lottie
                        options={{
                          animationData: checkBlue,
                          autoplay: true,
                          loop: true,
                          rendererSettings: {
                            preserveAspectRatio: 'xMidYMid slice',
                          },
                        }}
                        height={39}
                        width={39}
                      />
                      <h3 className="mb-0">Affiliates</h3>
                    </div>
                    <div className="pe-none d-flex align-items-center lootie my-3">
                      <Lottie
                        options={{
                          animationData: checkBlue,
                          autoplay: true,
                          loop: true,
                          rendererSettings: {
                            preserveAspectRatio: 'xMidYMid slice',
                          },
                        }}
                        height={39}
                        width={39}
                      />
                      <h3 className="mb-0">Email Marketers</h3>
                    </div>
                    <div className="pe-none d-flex align-items-center lootie my-3">
                      <Lottie
                        options={{
                          animationData: checkBlue,
                          autoplay: true,
                          loop: true,
                          rendererSettings: {
                            preserveAspectRatio: 'xMidYMid slice',
                          },
                        }}
                        height={39}
                        width={39}
                      />
                      <h3 className="mb-0">...PLUS WAY MORE!</h3>
                    </div>
                  </div>

                  {/* fim */}
                  <div className="col-lg-4 d-none d-lg-block">
                    <div className="pe-none d-flex align-items-center lootie mb-3">
                      <Lottie
                        options={{
                          animationData: checkBlue,
                          autoplay: true,
                          loop: true,
                          rendererSettings: {
                            preserveAspectRatio: 'xMidYMid slice',
                          },
                        }}
                        height={39}
                        width={39}
                      />
                      <h3 className="mb-0">Course Creators </h3>
                    </div>
                    <div className="pe-none d-flex align-items-center lootie my-3">
                      <Lottie
                        options={{
                          animationData: checkBlue,
                          autoplay: true,
                          loop: true,
                          rendererSettings: {
                            preserveAspectRatio: 'xMidYMid slice',
                          },
                        }}
                        height={39}
                        width={39}
                      />
                      <h3 className="mb-0">Coaches & Consultants</h3>
                    </div>
                    <div className="pe-none d-flex align-items-center lootie my-3">
                      <Lottie
                        options={{
                          animationData: checkBlue,
                          autoplay: true,
                          loop: true,
                          rendererSettings: {
                            preserveAspectRatio: 'xMidYMid slice',
                          },
                        }}
                        height={39}
                        width={39}
                      />
                      <h3 className="mb-0">Network Marketers</h3>
                    </div>
                    <div className="pe-none d-flex align-items-center lootie my-3">
                      <Lottie
                        options={{
                          animationData: checkBlue,
                          autoplay: true,
                          loop: true,
                          rendererSettings: {
                            preserveAspectRatio: 'xMidYMid slice',
                          },
                        }}
                        height={39}
                        width={39}
                      />
                      <h3 className="mb-0">Digital Product Sellers</h3>
                    </div>
                    <div className="pe-none d-flex align-items-center lootie my-3">
                      <Lottie
                        options={{
                          animationData: checkBlue,
                          autoplay: true,
                          loop: true,
                          rendererSettings: {
                            preserveAspectRatio: 'xMidYMid slice',
                          },
                        }}
                        height={39}
                        width={39}
                      />
                      <h3 className="mb-0">Speakers & Experts</h3>
                    </div>
                    <div className="pe-none d-flex align-items-center lootie my-3">
                      <Lottie
                        options={{
                          animationData: checkBlue,
                          autoplay: true,
                          loop: true,
                          rendererSettings: {
                            preserveAspectRatio: 'xMidYMid slice',
                          },
                        }}
                        height={39}
                        width={39}
                      />
                      <h3 className="mb-0">Authors & Bloggers</h3>
                    </div>
                  </div>
                  <div className="col-lg-4 d-none d-lg-block">
                    <div className="pe-none d-flex align-items-center lootie mb-3">
                      <Lottie
                        options={{
                          animationData: checkBlue,
                          autoplay: true,
                          loop: true,
                          rendererSettings: {
                            preserveAspectRatio: 'xMidYMid slice',
                          },
                        }}
                        height={39}
                        width={39}
                      />
                      <h3 className="mb-0">Ecommerce Stores</h3>
                    </div>
                    <div className="pe-none d-flex align-items-center lootie my-3">
                      <Lottie
                        options={{
                          animationData: checkBlue,
                          autoplay: true,
                          loop: true,
                          rendererSettings: {
                            preserveAspectRatio: 'xMidYMid slice',
                          },
                        }}
                        height={39}
                        width={39}
                      />
                      <h3 className="mb-0">Service Businesses</h3>
                    </div>
                    <div className="pe-none d-flex align-items-center lootie my-3">
                      <Lottie
                        options={{
                          animationData: checkBlue,
                          autoplay: true,
                          loop: true,
                          rendererSettings: {
                            preserveAspectRatio: 'xMidYMid slice',
                          },
                        }}
                        height={39}
                        width={39}
                      />
                      <h3 className="mb-0">B2B Companies</h3>
                    </div>
                    <div className="pe-none d-flex align-items-center lootie my-3">
                      <Lottie
                        options={{
                          animationData: checkBlue,
                          autoplay: true,
                          loop: true,
                          rendererSettings: {
                            preserveAspectRatio: 'xMidYMid slice',
                          },
                        }}
                        height={39}
                        width={39}
                      />
                      <h3 className="mb-0">Local Stores</h3>
                    </div>
                    <div className="pe-none d-flex align-items-center lootie my-3">
                      <Lottie
                        options={{
                          animationData: checkBlue,
                          autoplay: true,
                          loop: true,
                          rendererSettings: {
                            preserveAspectRatio: 'xMidYMid slice',
                          },
                        }}
                        height={39}
                        width={39}
                      />
                      <h3 className="mb-0">Professional Services</h3>
                    </div>
                    <div className="pe-none d-flex align-items-center lootie my-3">
                      <Lottie
                        options={{
                          animationData: checkBlue,
                          autoplay: true,
                          loop: true,
                          rendererSettings: {
                            preserveAspectRatio: 'xMidYMid slice',
                          },
                        }}
                        height={39}
                        width={39}
                      />
                      <h3 className="mb-0">Contractors</h3>
                    </div>
                  </div>
                  <div className="col-lg-4 d-none d-lg-block">
                    <div className="pe-none d-flex align-items-center lootie mb-3">
                      <Lottie
                        options={{
                          animationData: checkBlue,
                          autoplay: true,
                          loop: true,
                          rendererSettings: {
                            preserveAspectRatio: 'xMidYMid slice',
                          },
                        }}
                        height={39}
                        width={39}
                      />
                      <h3 className="mb-0">Agencies & Freelancers</h3>
                    </div>
                    <div className="pe-none d-flex align-items-center lootie my-3">
                      <Lottie
                        options={{
                          animationData: checkBlue,
                          autoplay: true,
                          loop: true,
                          rendererSettings: {
                            preserveAspectRatio: 'xMidYMid slice',
                          },
                        }}
                        height={39}
                        width={39}
                      />
                      <h3 className="mb-0">Digital Marketers</h3>
                    </div>
                    <div className="pe-none d-flex align-items-center lootie my-3">
                      <Lottie
                        options={{
                          animationData: checkBlue,
                          autoplay: true,
                          loop: true,
                          rendererSettings: {
                            preserveAspectRatio: 'xMidYMid slice',
                          },
                        }}
                        height={39}
                        width={39}
                      />
                      <h3 className="mb-0">Copywriters</h3>
                    </div>
                    <div className="pe-none d-flex align-items-center lootie my-3">
                      <Lottie
                        options={{
                          animationData: checkBlue,
                          autoplay: true,
                          loop: true,
                          rendererSettings: {
                            preserveAspectRatio: 'xMidYMid slice',
                          },
                        }}
                        height={39}
                        width={39}
                      />
                      <h3 className="mb-0">Affiliates</h3>
                    </div>
                    <div className="pe-none d-flex align-items-center lootie my-3">
                      <Lottie
                        options={{
                          animationData: checkBlue,
                          autoplay: true,
                          loop: true,
                          rendererSettings: {
                            preserveAspectRatio: 'xMidYMid slice',
                          },
                        }}
                        height={39}
                        width={39}
                      />
                      <h3 className="mb-0">Email Marketers</h3>
                    </div>
                    <div className="pe-none d-flex align-items-center lootie my-3">
                      <Lottie
                        options={{
                          animationData: checkBlue,
                          autoplay: true,
                          loop: true,
                          rendererSettings: {
                            preserveAspectRatio: 'xMidYMid slice',
                          },
                        }}
                        height={39}
                        width={39}
                      />
                      <h3 className="mb-0">...PLUS WAY MORE!</h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Perfect>

        <Gpt>
          <div className="container zoom">
            <div className="row justify-content-center">
              <div className="col-12 my-5">
                <h2 className="my-4">
                  All of this for <span>$97</span> <span>FREE</span>
                </h2>
                <div className="text-center">
                  <button
                    type="button"
                    className="btn-download mx-auto"
                    onClick={handleClick}
                  >
                    <Lottie
                      options={{
                        animationData: btnDownload,
                        autoplay: true,
                        loop: true,
                        rendererSettings: {
                          preserveAspectRatio: 'xMidYMid slice',
                        },
                      }}
                      height={90}
                      width={90}
                    />
                    <span>
                      Download Now
                      <br />
                      <span>...& Get Instant Access</span>
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Gpt>
        <Perfect>
          <div className="container zoom">
            <div className="row justify-content-center">
              <div className="col-md-6 ps-lg-5 mt-5 pt-lg-3 mb-md-5 pb-4">
                <img src={messages1} alt="Imagem messages" className="w-100" />
              </div>
              <div className="col-md-6 pe-lg-5 mt-md-5 pt-lg-3">
                <img src={messages2} alt="Imagem messages" className="w-100" />
              </div>
            </div>
          </div>
        </Perfect>
        <Easy>
          <div className="container zoom">
            <div className="row justify-content-center">
              <div className="col-12">
                <h2 className="">
                  <b>Here's How Easy It Is To Succeed With</b> Our Amazing AI
                  Content Prompts...
                </h2>
              </div>
              <div className="col-lg-11 px-4 px-sm-3">
                <div className="d-flex align-items-center mt-5 pt-3">
                  <div className="bg-white p-2 rounded-circle">
                    <div className="number">
                      <span>1</span>
                    </div>
                  </div>
                  <h3>
                    Open up “Your Ultimate Guide To Creating Magnetic Social
                    Content” <b>on your computer, tablet or mobile device...</b>
                  </h3>
                </div>
                <div className="line" />
                <div className="d-flex align-items-center">
                  <div className="bg-white p-2 rounded-circle">
                    <div className="number">
                      <span>2</span>
                    </div>
                  </div>
                  <h3>
                    Copy & paste one of the powerful prompts into ChatGPT/Claude{' '}
                    <b>(They work with both the FREE or paid version!)</b>
                  </h3>
                </div>
                <div className="line" />
                <div className="d-flex align-items-center">
                  <div className="bg-white p-2 rounded-circle">
                    <div className="number">
                      <span>3</span>
                    </div>
                  </div>
                  <h3 className="mb-5 mb-md-0">
                    <b>
                      Instantly get high-quality social content that attracts
                      and converts
                    </b>{' '}
                    more customers than ever before... without needing to spend
                    hours writing content for your social sites!
                  </h3>
                </div>
              </div>
              <div className="col-12 pt-5 pt-lg-0 mt-5 pe-none">
                <Lottie
                  options={{
                    animationData: ArrowDown,
                    autoplay: true,
                    loop: true,
                    rendererSettings: {
                      preserveAspectRatio: 'xMidYMid slice',
                    },
                  }}
                  height={150}
                  width={150}
                />
              </div>
            </div>
          </div>
          <div className="text-center">
            <img
              src={width > 575 ? devices : devicesMob}
              alt="Devices"
              className="w-100"
            />
          </div>
        </Easy>
        <Unlimited>
          <div className="container zoom">
            <div className="row justify-content-center">
              <div className="col-lg-11 pe-none">
                <h2 className="mb-4 pb-3 px-4 px-sm-0">
                  You’re Just Moments Away From <b>Getting Unlimited Content</b>{' '}
                  For Your Social Sites…&nbsp;
                  <span>and More!</span>
                </h2>
              </div>
              <div className="col-lg-10 px-lg-4 mt-5 pt-3">
                <div className="px-3 px-sm-0">
                  <p>
                    It’s true what they’re saying about ChatGPT and other AI
                    sites...
                  </p>
                  <p>
                    You really can get an endless stream of high-quality content
                    for your business (or your client’s).
                  </p>
                  <p>
                    Content for Facebook, Instagram, Twitter (X), Threads,
                    TikTok and YouTube can be instantly created on AI now.
                  </p>
                  <p>
                    <b>
                      Gone are the days of sitting around trying to figure out
                      what to write.
                    </b>
                  </p>
                  <p>Hello plug and play. </p>
                  <p>
                    That’s how easy it is to use our <b>FREE prompts.</b>
                  </p>
                  <p>
                    <span>Here is how it works:</span>
                  </p>
                  <p className="mb-0">1. Copy/paste into ChatGPT/Claude</p>
                  <p>2. Copy and paste to Social sites...DONE!</p>
                </div>
              </div>

              <div className="col-lg-11 px-lg-4 mt-5 pt-4">
                <div className="px-4 px-sm-0">
                  <span className="d-block text-center">
                    I know you must be thinking....
                  </span>
                  <h3 className="text-center">
                    Are you insane?{' '}
                    <span className="gradient">Why are you giving</span>{' '}
                    <span className="gradient">this away for free?</span>
                  </h3>
                </div>
              </div>

              <div className="col-lg-10 px-lg-4 mt-5 pt-4">
                <div className="px-4 px-sm-0">
                  <p>
                    <b className="fst-italic">
                      Here is the simple fact. We want to gain your trust.
                    </b>
                  </p>
                  <p>
                    By giving away something so valuable, something you would
                    normally have to pay for, we do just that, earn trust.
                  </p>
                  <p>And then later…</p>
                  <p>
                    <b>We might just ask you to buy something.</b>
                  </p>
                  <p>
                    And if we do, you will be more inclined to buy, since you
                    know we do really great work and produce amazing products.
                  </p>
                </div>
                <div className="pe-none py-5">
                  <Lottie
                    options={{
                      animationData: ArrowDown,
                      autoplay: true,
                      loop: true,
                      rendererSettings: {
                        preserveAspectRatio: 'xMidYMid slice',
                      },
                    }}
                    height={83}
                    width={83}
                  />
                </div>
                <div className="px-4 px-sm-0 mt-5">
                  <p>
                    <b>
                      This is your guaranteed shortcut to AI content success...
                    </b>
                  </p>
                  <p>
                    Forget about hiring expensive copywriters, or wasting hours
                    staring at a blank screen.
                  </p>
                  <p>
                    Now you can get all the high-converting AI prompts you’ll
                    ever need to create an endless supply of incredible content.
                  </p>
                </div>
                <div className="py-4">
                  <button
                    type="button"
                    className="mx-auto"
                    onClick={handleClick}
                  >
                    YES... Send Me The Ultimate Guide Now!
                    <span>
                      <Lottie
                        options={{
                          animationData: ArrowYellow,
                          autoplay: true,
                          loop: true,
                          rendererSettings: {
                            preserveAspectRatio: 'xMidYMid slice',
                          },
                        }}
                        height={68}
                        width={68}
                      />
                    </span>
                  </button>
                  <p className="text-btn mt-3 mb-0 pb-5">
                    Hurry! This offer will end soon!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Unlimited>

        <Bonus>
          <div className="container zoom">
            <div className="row justify-content-center">
              <div className="col-lg-8 pt-5">
                <h2 className="mb-5 pt-5 pb-5">
                  PLUS... <br />
                  Special <span>FREE Bonuses!</span>
                </h2>
              </div>
              <div className="col-lg-11 px-2 px-sm-3 pt-5">
                <div className="bg-pp1-transparent pt-5 pb-4 px-3 px-sm-5 position-relative">
                  <div className="position-absolute papper-top-1">
                    <img src={papperTop} alt="Papper" />
                    <h5>Bonus #1</h5>
                  </div>
                  <div className="row">
                    <div className="col-1 d-flex justify-content-center align-items-lg-center pt-4 pt-lg-0">
                      <div className="vertical-bar" />
                    </div>
                    <div className="col-11">
                      <div className="row">
                        <div className="col-12">
                          <h3 className="mb-3 mt-4">
                            "The Essential Guide to Power Niches"
                          </h3>
                          <p className="mb-3 w-90">
                            Look, we want to really make this a no brainer for
                            you. Therefore, we have developed prompts for every
                            major online niche. They are done-for-you prompts
                            tailored to your business. It doesn’t get much
                            better than this!
                          </p>
                          <h6>These include:</h6>
                        </div>
                        <div className="col-12 d-lg-flex">
                          <div className="pe-lg-4">
                            <ul>
                              <li>Financial Advisors</li>
                              <li>Fitness and Weight Loss</li>
                              <li>Technology</li>
                              <li>Dating and Relationships</li>
                              <li>Personal Finance</li>
                              <li>Self Improvement</li>
                              <li>Networking</li>
                            </ul>
                          </div>
                          <div className="pe-lg-4">
                            <ul>
                              <li>Personal Development</li>
                              <li>Community Building & Engagement</li>
                              <li>Make Money on the Internet</li>
                              <li>Lead Generation Social Media Posts</li>
                              <li>Network Marketers</li>
                              <li>Wealth Building Through Investing</li>
                              <li>Relationship Building</li>
                            </ul>
                          </div>
                          <div className="">
                            <ul>
                              <li>Gaming</li>
                              <li>Realtors</li>
                              <li>Babies</li>
                              <li>Pets</li>
                              <li>Health</li>
                              <li>Travel</li>
                              <li>Plus More</li>
                            </ul>
                          </div>
                        </div>
                        <div className="col-lg-10">
                          <p className="text-center text-lg-end value pe-lg-5 my-5 my-lg-0">
                            Value=<span>$197</span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <img src={bonus1} alt="Bonus" className="tablet" />
                </div>
                <div className="d-flex align-items-center mb-2 mb-lg-0 pe-none mt-lootie">
                  <Lottie
                    options={{
                      animationData: plus,
                      autoplay: true,
                      loop: true,
                      rendererSettings: {
                        preserveAspectRatio: 'xMidYMid slice',
                      },
                    }}
                    height={400}
                    width={400}
                  />
                </div>
              </div>

              <div className="col-lg-11 pt-5">
                <div className="bg-pp2-transparent pt-5 pb-4 px-3 px-sm-5 position-relative">
                  <div className="position-absolute papper-top-2">
                    <img src={papperTop} alt="Papper" />
                    <h5>Bonus #2</h5>
                  </div>
                  <div className="row">
                    <div className="col-1 d-flex justify-content-center align-items-lg-center pt-4 pt-lg-0">
                      <div className="vertical-bar" />
                    </div>
                    <div className="col-11">
                      <div className="row">
                        <div className="col-12">
                          <h3 className="mb-3 mt-4">
                            "Ideas to Leverage ChatGPT for&nbsp;Profit"
                          </h3>
                          <p className="mb-3 w-lg-75">
                            If you are like us, you have probably started
                            imagining “How else could I make money from
                            ChatGPT”? Well, we went one step further. We put
                            together an entire list of potential ways of killing
                            it even more with ChatGPT. These are simple ideas
                            that you can get setup right away once you learn how
                            to master the prompts. You can now have multiple
                            different ways of making money from the most
                            revolutionary tool to hit the market since the
                            beginning of the Internet.
                          </p>
                        </div>

                        <div className="col-lg-10">
                          <p className="text-center text-lg-end value pe-lg-5 my-5 my-lg-0">
                            Value=<span>$497</span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <img src={bonus2} alt="Bonus" className="tablet" />
                </div>
                <div className="d-flex align-items-center mb-2 mb-lg-0 pe-none mt-lootie">
                  <Lottie
                    options={{
                      animationData: plus,
                      autoplay: true,
                      loop: true,
                      rendererSettings: {
                        preserveAspectRatio: 'xMidYMid slice',
                      },
                    }}
                    height={400}
                    width={400}
                  />
                </div>
              </div>

              <div className="col-lg-11 pt-5 mb-bonus">
                <div className="bg-pp3-transparent pt-5 pb-4 px-3 px-sm-5 position-relative">
                  <div className="position-absolute papper-top-3">
                    <img src={papperTop} alt="Papper" />
                    <h5>Bonus #3</h5>
                  </div>
                  <div className="row">
                    <div className="col-1 d-flex justify-content-center align-items-lg-center pt-4 pt-lg-0">
                      <div className="vertical-bar" />
                    </div>
                    <div className="col-11">
                      <div className="row">
                        <div className="col-12">
                          <h3 className="mb-3 mt-4">
                            "The 151 Top AI Tools and Resources"
                          </h3>
                          <p className="mb-3 w-lg-75">
                            Now you can supercharge your business and
                            dramatically increase your productivity with this
                            handy list of 151 Top AI tools and resources.Whether
                            you're looking to automate tasks, create images &
                            videos with AI, or simply make your life easier,
                            these tools and resources have got you covered.
                          </p>
                          <p className="mb-3 w-lg-75">
                            And with new tools and software being added
                            frequently, you'll always be on the cutting edge of
                            the latest AI advancements.
                          </p>
                        </div>

                        <div className="col-lg-10">
                          <p className="text-center text-lg-end value pe-lg-5 my-5 my-lg-0">
                            Value=<span>$297</span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <img src={bonus3} alt="Bonus" className="tablet" />
                </div>
              </div>
              <div className="col-lg-11">
                <h2 className="fw-bold px-3">
                  Now, our total value is the following:
                </h2>
              </div>
              <div className="col-max-mobile col-sm-10 col-md-12 col-lg-10">
                <div className="d-flex align-items-center mt-5 pt-3">
                  <div className="bg-1 p-2 rounded-circle">
                    <div className="number">
                      <span>1</span>
                    </div>
                  </div>
                  <h3 className="total-value mb-0 ps-4 ms-sm-2">
                    <b>Bonus 1:</b> The Essential Guide
                    <br className="d-md-none" /> to Power Niches
                    <span className="d-lg-none">………………</span>
                    <span className="d-none d-lg-inline-flex">……………</span>
                    <b className="font-size">$197</b>
                  </h3>
                </div>
                <div className="line" />
                <div className="d-flex align-items-center">
                  <div className="bg-2 p-2 rounded-circle">
                    <div className="number">
                      <span>2</span>
                    </div>
                  </div>
                  <h3 className="total-value mb-0 ps-4 ms-sm-2">
                    <b>Bonus 2:</b> Ideas to Leverage
                    <br className="d-md-none" /> ChatGPT for Profit
                    <span className="d-lg-none">………</span>
                    <span className="d-none d-lg-inline-flex">…………</span>
                    <b className="font-size">$497</b>
                  </h3>
                </div>
                <div className="line" />
                <div className="d-flex align-items-center">
                  <div className="bg-3 p-2 rounded-circle">
                    <div className="number">
                      <span>3</span>
                    </div>
                  </div>
                  <h3 className="total-value mb-0 ps-4 ms-sm-2">
                    <b>Bonus 3:</b> The 151 Top AI Tools
                    <br className="d-md-none" /> and Resources
                    <span className="d-lg-none">………………</span>
                    <span className="d-none d-lg-inline-flex">……………</span>
                    <b className="font-size">$297</b>
                  </h3>
                </div>
                <div className="line" />
                <div className="d-flex align-items-center">
                  <div className="bg-4 p-2 rounded-circle">
                    <div className="number">
                      <span>4</span>
                    </div>
                  </div>
                  <h3 className="total-value mb-0 ps-4 ms-sm-2">
                    <b>The Book:</b> The Ultimate
                    <br className="d-md-none" /> Guide To Creating{' '}
                    <br className="d-none d-md-block" />
                    Magnetic
                    <br className="d-md-none" /> Social Content
                    <span className="d-md-none">…………………</span>
                    <span className="d-none d-md-inline-flex">
                      ………………………………………………………
                    </span>
                    <b className="font-size">$97</b>
                  </h3>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-1 d-none d-lg-block" />
              <div className="col-lg-10">
                <p className="total mt-5 pt-2 text-center text-lg-end">
                  Total Value <span className="d-fill d-md-none">…</span>
                  <span className="d-none d-md-inline-block">
                    ………………………………
                  </span>{' '}
                  <span className="color-value">$1,088</span>
                </p>
              </div>
              <div className="col-1 d-none d-lg-block" />
              <div className="col-lg-10">
                <h2 className="only mt-5 pt-5">
                  You get absolutely everything here instantly for{' '}
                  <span>FREE</span>
                </h2>
                <h3 className="saving-text my-5 py-2">That is just insane!</h3>
                <h4 className="dont mb-5 pb-4">
                  Don't Miss Out!
                  <br />
                  Get This Today Before We Start Charging for the Guide...
                </h4>
                <div className="py-4">
                  <button
                    type="button"
                    className="mx-auto"
                    onClick={handleClick}
                  >
                    YES... Send Me The Ultimate Guide Now!
                    <span>
                      <Lottie
                        options={{
                          animationData: ArrowYellow,
                          autoplay: true,
                          loop: true,
                          rendererSettings: {
                            preserveAspectRatio: 'xMidYMid slice',
                          },
                        }}
                        height={68}
                        width={68}
                      />
                    </span>
                  </button>
                  <p className="text-btn-send my-4 pb-3 mb-5">
                    Hurry! This offer will end soon!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Bonus>
        <Questions>
          <div className="container zoom">
            <div className="row justify-content-center">
              <div className="col-lg-10">
                <h2 className="mb-5 pt-5">Frequently Asked Questions...</h2>
              </div>
              <div className="col-11 my-sm-5 border-questions">
                <div
                  className={`position-relative row align-items-center padding-y px-3 px-sm-4 px-md-5 border-bottom hover-top ${
                    answer === 'question_1' ? 'bg-answer' : ''
                  }`}
                >
                  <button
                    type="button"
                    onClick={() => handleClickQuestion('question_1')}
                    className="btn-plus position-absolute w-100 h-100 border-0 bg-transparent"
                  />
                  <div className="col-sm-2 col-lg-1 text-center pl-0 pl-sm-3">
                    <span className="h2-sm h1-xl mb-0 fw-bold">01</span>
                  </div>
                  <div className="col-sm-8 col-lg-10 px-0 px-sm-3 text-center text-sm-start my-4 my-sm-0">
                    <p className="h5-sm h4-xl mb-0 text-grey">
                      What Exactly Is “Your Ultimate Guide To Creating Magnetic
                      Social Content”?
                    </p>
                  </div>
                  <div className="col-sm-2 col-lg-1 text-center px-0">
                    <button type="button" className="border-0 bg-transparent">
                      <span className="h4 h3-xl d-flex align-items-center justify-content-center btn-see-more border rounded-circle mx-auto mb-0">
                        {answer !== 'question_1' ? (
                          <BsPlus size={25} />
                        ) : (
                          <BsDash size={25} />
                        )}
                      </span>
                    </button>
                  </div>
                  <div className="col-2 col-lg-1 d-none d-lg-block" />
                  <div className="col-12 col-lg-10">
                    <p
                      className={`text-center text-sm-start answer mb-0 font-weight-400 ${
                        answer === 'question_1' ? 'active mt-4' : ''
                      }`}
                    >
                      "Your Ultimate Guide To Creating Magnetic Social Content"
                      is a comprehensive collection of over 3500+ marketing
                      prompts and 70+ dynamic content prompts designed for every
                      step of your social media strategy. <br />
                      <br />
                      Whether you're crafting posts for Facebook, Instagram,
                      Twitter (X), Threads, TikTok, or YouTube, these prompts
                      help you create engaging, high-converting content
                      effortlessly. Simply copy & paste one of the powerful
                      prompts into ChatGPT/Claude and instantly get tailored
                      content that resonates with your audience.
                    </p>
                  </div>
                  <div className="col-1 d-none d-lg-block" />
                </div>

                <div
                  className={`position-relative row align-items-center padding-y px-3 px-sm-4 px-md-5 border-bottom hover ${
                    answer === 'question_2' ? 'bg-answer' : ''
                  }`}
                >
                  <button
                    type="button"
                    onClick={() => handleClickQuestion('question_2')}
                    className="btn-plus position-absolute w-100 h-100 border-0 bg-transparent"
                  />
                  <div className="col-sm-2 col-lg-1 text-center pl-0 pl-sm-3">
                    <span className="h2-sm h1-xl mb-0 fw-bold">02</span>
                  </div>
                  <div className="col-sm-8 col-lg-10 px-0 px-sm-3 text-center text-sm-start my-4 my-sm-0">
                    <p className="h6 h5-sm h4-xl mb-0 text-grey ">
                      Will This Work With The FREE Version Of ChatGPT?
                    </p>
                  </div>
                  <div className="col-sm-2 col-lg-1 text-center px-0">
                    <button type="button" className="border-0 bg-transparent">
                      <span className="h4 h3-xl d-flex align-items-center justify-content-center btn-see-more border rounded-circle mx-auto mb-0">
                        {answer !== 'question_2' ? (
                          <BsPlus size={25} />
                        ) : (
                          <BsDash size={25} />
                        )}
                      </span>
                    </button>
                  </div>
                  <div className="col-2 col-lg-1 d-none d-lg-block" />
                  <div className="col-12 col-lg-10">
                    <p
                      className={`text-center text-sm-start answer mb-0 font-weight-400 ${
                        answer === 'question_2' ? 'active mt-4' : ''
                      }`}
                    >
                      Yes!
                      <br />
                      <br />
                      "Your Ultimate Guide To Creating Magnetic Social Content"
                      works seamlessly with both the FREE and paid versions of
                      ChatGPT.
                      <br />
                      <br />
                      The free version will give you excellent results, while
                      the paid version may provide faster outputs and access to
                      the latest features. Additionally, these prompts can be
                      used with other AI content creation tools you might
                      already be using.
                    </p>
                  </div>
                  <div className="col-1 d-none d-lg-block" />
                </div>

                <div
                  className={`position-relative row align-items-center padding-y px-3 px-sm-4 px-md-5 border-bottom hover ${
                    answer === 'question_3' ? 'bg-answer' : ''
                  }`}
                >
                  <button
                    type="button"
                    onClick={() => handleClickQuestion('question_3')}
                    className="btn-plus position-absolute w-100 h-100 border-0 bg-transparent"
                  />
                  <div className="col-sm-2 col-lg-1 text-center pl-0 pl-sm-3">
                    <span className="h2-sm h1-xl mb-0 fw-bold">03</span>
                  </div>
                  <div className="col-sm-8 col-lg-10 px-0 px-sm-3 text-center text-sm-start my-4 my-sm-0">
                    <p className="h5-sm h4-xl mb-0 text-grey">Why Is Free?</p>
                  </div>
                  <div className="col-sm-2 col-lg-1 text-center px-0">
                    <button type="button" className="border-0 bg-transparent">
                      <span className="h4 h3-xl d-flex align-items-center justify-content-center btn-see-more border rounded-circle mx-auto mb-0">
                        {answer !== 'question_3' ? (
                          <BsPlus size={25} />
                        ) : (
                          <BsDash size={25} />
                        )}
                      </span>
                    </button>
                  </div>
                  <div className="col-2 col-lg-1 d-none d-lg-block" />
                  <div className="col-12 col-lg-10">
                    <p
                      className={`text-center text-sm-start answer mb-0 font-weight-400 ${
                        answer === 'question_3' ? 'active mt-4' : ''
                      }`}
                    >
                      We're offering "Your Ultimate Guide To Creating Magnetic
                      Social Content" for free as part of a special marketing
                      campaign to build trust and gather testimonials.
                      <br />
                      <br />
                      Normally priced at $97, this guide is available to you for
                      free right now. Our hope is that by providing you with
                      this valuable resource at no cost, you'll see the quality
                      of our work and consider purchasing our other products and
                      services in the future.
                    </p>
                  </div>
                  <div className="col-1 d-none d-lg-block" />
                </div>

                <div
                  className={`position-relative row align-items-center padding-y px-3 px-sm-4 px-md-5 border-bottom hover ${
                    answer === 'question_4' ? 'bg-answer' : ''
                  }`}
                >
                  <button
                    type="button"
                    onClick={() => handleClickQuestion('question_4')}
                    className="btn-plus position-absolute w-100 h-100 border-0 bg-transparent"
                  />
                  <div className="col-sm-2 col-lg-1 text-center pl-0 pl-sm-3">
                    <span className="h2-sm h1-xl mb-0 fw-bold">04</span>
                  </div>
                  <div className="col-sm-8 col-lg-10 px-0 px-sm-3 text-center text-sm-start my-4 my-sm-0">
                    <p className="h6 h5-sm h4-xl mb-0 text-grey ">
                      When Do I Get Access To All The Prompts & Bonuses?
                    </p>
                  </div>
                  <div className="col-sm-2 col-lg-1 text-center px-0">
                    <button type="button" className="border-0 bg-transparent">
                      <span className="h4 h3-xl d-flex align-items-center justify-content-center btn-see-more border rounded-circle mx-auto mb-0">
                        {answer !== 'question_4' ? (
                          <BsPlus size={25} />
                        ) : (
                          <BsDash size={25} />
                        )}
                      </span>
                    </button>
                  </div>
                  <div className="col-2 col-lg-1 d-none d-lg-block" />
                  <div className="col-12 col-lg-10">
                    <p
                      className={`text-center text-sm-start answer mb-0 font-weight-400 ${
                        answer === 'question_4' ? 'active mt-4' : ''
                      }`}
                    >
                      Immediately!
                      <br />
                      <br />
                      You get instant access to all the prompts and bonuses as
                      soon as you enter your name and email.
                      <br />
                      <br />
                      PLUS, all the prompts are neatly organized and labelled so
                      you can get started right away :)
                    </p>
                  </div>
                  <div className="col-1 d-none d-lg-block" />
                </div>

                <div
                  className={`position-relative row align-items-center padding-y px-3 px-sm-4 px-md-5 border-bottom hover ${
                    answer === 'question_5' ? 'bg-answer' : ''
                  }`}
                >
                  <button
                    type="button"
                    onClick={() => handleClickQuestion('question_5')}
                    className="btn-plus position-absolute w-100 h-100 border-0 bg-transparent"
                  />
                  <div className="col-sm-2 col-lg-1 text-center pl-0 pl-sm-3">
                    <span className="h2-sm h1-xl mb-0 fw-bold">05</span>
                  </div>
                  <div className="col-sm-8 col-lg-10 px-0 px-sm-3 text-center text-sm-start my-4 my-sm-0">
                    <p className="h5-sm h4-xl mb-0 text-grey">
                      Who Is "The Ultimate Guide To Creating Magnetic Social
                      Content" For?
                    </p>
                  </div>
                  <div className="col-sm-2 col-lg-1 text-center px-0">
                    <button type="button" className="border-0 bg-transparent">
                      <span className="h4 h3-xl d-flex align-items-center justify-content-center btn-see-more border rounded-circle mx-auto mb-0">
                        {answer !== 'question_5' ? (
                          <BsPlus size={25} />
                        ) : (
                          <BsDash size={25} />
                        )}
                      </span>
                    </button>
                  </div>
                  <div className="col-2 col-lg-1 d-none d-lg-block" />
                  <div className="col-12 col-lg-10">
                    <p
                      className={`text-center text-sm-start answer mb-0 font-weight-400 ${
                        answer === 'question_5' ? 'active mt-4' : ''
                      }`}
                    >
                      This guide is perfect for <b>ANYONE</b> looking to enhance
                      their social media presence and boost online sales.
                      <br />
                      <br />
                      <b>It's perfect for....</b> Course Creators, Coaches &
                      Consultants, Network Marketers, Digital Product Sellers,
                      Speakers & Experts, Authors & Bloggers, Ecommerce Stores,
                      Service Businesses, B2B Companies, Local Stores,
                      Professional Services, Contractors, Agencies &
                      Freelancers, Digital Marketers, Copywriters, Affiliates,
                      Email Marketers... <b>AND MANY MORE!</b>
                    </p>
                  </div>
                  <div className="col-1 d-none d-lg-block" />
                </div>

                <div
                  className={`position-relative row align-items-center padding-y px-3 px-sm-4 px-md-5 border-bottom hover ${
                    answer === 'question_6' ? 'bg-answer' : ''
                  }`}
                >
                  <button
                    type="button"
                    onClick={() => handleClickQuestion('question_6')}
                    className="btn-plus position-absolute w-100 h-100 border-0 bg-transparent"
                  />
                  <div className="col-sm-2 col-lg-1 text-center pl-0 pl-sm-3">
                    <span className="h2-sm h1-xl mb-0 fw-bold">06</span>
                  </div>
                  <div className="col-sm-8 col-lg-10 px-0 px-sm-3 text-center text-sm-start my-4 my-sm-0">
                    <p className="h6 h5-sm h4-xl mb-0 text-grey ">
                      How Do I Get Help If There's Issues With My Free Access?
                    </p>
                  </div>
                  <div className="col-sm-2 col-lg-1 text-center px-0">
                    <button type="button" className="border-0 bg-transparent">
                      <span className="h4 h3-xl d-flex align-items-center justify-content-center btn-see-more border rounded-circle mx-auto mb-0">
                        {answer !== 'question_6' ? (
                          <BsPlus size={25} />
                        ) : (
                          <BsDash size={25} />
                        )}
                      </span>
                    </button>
                  </div>
                  <div className="col-2 col-lg-1 d-none d-lg-block" />
                  <div className="col-12 col-lg-10">
                    <p
                      className={`text-center text-sm-start answer mb-0 font-weight-400 ${
                        answer === 'question_6' ? 'active mt-4' : ''
                      }`}
                    >
                      We're here to help you succeed!
                      <br />
                      <br />
                      Simply submit your questions via our support button on the
                      button right corner of the page and we will respond within
                      24 hours.
                    </p>
                  </div>
                  <div className="col-1 d-none d-lg-block" />
                </div>

                <div
                  className={`position-relative row align-items-center padding-y px-3 px-sm-4 px-md-5 hover-bottom ${
                    answer === 'question_7' ? 'bg-answer' : ''
                  }`}
                >
                  <button
                    type="button"
                    onClick={() => handleClickQuestion('question_7')}
                    className="btn-plus position-absolute w-100 h-100 border-0 bg-transparent"
                  />
                  <div className="col-sm-2 col-lg-1 text-center pl-0 pl-sm-3">
                    <span className="h2-sm h1-xl mb-0 fw-bold">07</span>
                  </div>
                  <div className="col-sm-8 col-lg-10 text-center text-sm-start my-4 my-sm-0">
                    <p className="h5-sm h4-xl mb-0 text-grey">
                      If I Use The Prompts, Won’t My Content Look The Same As
                      Everyone Else?
                    </p>
                  </div>
                  <div className="col-sm-2 col-lg-1 text-center px-0 px-xl-3">
                    <button type="button" className="border-0 bg-transparent">
                      <span className="h4 h3-xl d-flex align-items-center justify-content-center btn-see-more border rounded-circle mx-auto mb-0">
                        {answer !== 'question_7' ? (
                          <BsPlus size={25} />
                        ) : (
                          <BsDash size={25} />
                        )}
                      </span>
                    </button>
                  </div>
                  <div className="col-2 col-lg-1 d-none d-lg-block" />
                  <div className="col-12 col-lg-10">
                    <p
                      className={`text-center text-sm-start answer mb-0 font-weight-400 ${
                        answer === 'question_7' ? 'active mt-4' : ''
                      }`}
                    >
                      Great question!
                      <br />
                      <br />
                      ChatGPT generates 100% unique content every time.
                      <br />
                      <br />
                      Even if you use the same prompt multiple times, you'll get
                      different outputs each time.
                      <br />
                      <br />
                      Plus, you can edit and customize the prompts to fit your
                      brand's voice and style, ensuring your content stands out.
                    </p>
                  </div>
                  <div className="col-1 d-none d-lg-block" />
                </div>
              </div>
            </div>
          </div>
        </Questions>

        <Current>
          <div className="container zoom">
            <div className="row justify-content-center">
              <div className="col-lg-10">
                <div className="py-4">
                  <button
                    type="button"
                    className="mx-auto"
                    onClick={handleClick}
                  >
                    YES... Send Me The Ultimate Guide Now!
                    <span>
                      <Lottie
                        options={{
                          animationData: ArrowYellow,
                          autoplay: true,
                          loop: true,
                          rendererSettings: {
                            preserveAspectRatio: 'xMidYMid slice',
                          },
                        }}
                        height={68}
                        width={68}
                      />
                    </span>
                  </button>
                </div>
              </div>

              <div className="col-sm-11 my-5 py-5">
                <DisclaimerNew />
              </div>
            </div>
          </div>
        </Current>
        <ModalEnterEmail
          id="enterEmail"
          btnText=""
          btnContinueText="DOWNLOAD FREE GUIDE NOW"
          className="d-none"
          origin="Ultimate Guide Page"
        />
      </Container>
    </>
  );
};

export default UltimateGuide;
