import React, {
  FC,
  ComponentType,
  useMemo,
  useState,
  useCallback,
  useEffect,
  useLayoutEffect,
} from 'react';
import {
  Route as ReactDOMRoute,
  RouteProps as ReactDOMRouterProps,
  Redirect,
  useLocation,
} from 'react-router-dom';
import { useAuth } from '../hooks/Auth';

import Auth from '~/pages/_Layouts/Auth';
import Default from '~/pages/_Layouts/Default';
import Footer from '~/components/Footer';

interface RouteProps extends ReactDOMRouterProps {
  isPrivate?: boolean;
  component: ComponentType;
}

const Route: FC<RouteProps> = ({
  isPrivate = false,
  component: Component,
  ...rest
}) => {
  const locationPage = useLocation();
  const { user } = useAuth();

  const noFooter = useMemo(
    () => [
      /\/discover.*/,
      /\/webinar.*/,
      /\/live-webinar.*/,
      /\/live-video.*/,
      /\/lp.*/,
      /\/vip.*/,
      /\/pe-welcome.*/,
      /\/aic.*/,
    ],
    []
  );

  const Layout = useMemo(() => {
    return isPrivate ? Default : Auth;
  }, [isPrivate]);

  return (
    <ReactDOMRoute
      {...rest}
      render={({ location }) => {
        return isPrivate === !!user ? (
          <Layout>
            <Component />
            {noFooter.every((item) => !item.test(locationPage.pathname)) && (
              <Footer />
            )}
          </Layout>
        ) : (
          <Redirect
            to={{
              pathname: isPrivate ? `/` : `/`,
              state: location,
            }}
          />
        );
      }}
    />
  );
};

export default Route;
