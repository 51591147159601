import { darken } from 'polished';
import styled from 'styled-components';

interface IAvatarProps {
  src: string;
}

interface ICopyAlertProps {
  show: boolean;
}

export const Container = styled.div`
  background-color: #f9f9f9;
`;

export const Attention = styled.div`
  background: #ff1a50;
  box-shadow: 0px 4px 4px rgba(255, 26, 80, 0.15);
  position: relative;
  z-index: 1;

  h2 {
    font-weight: 400 !important;
    font-family: 'Inter';
  }

  svg {
    height: 30px;
    width: 30px;
  }

  @media screen and (max-width: 991px) {
    svg {
      height: 25px;
      width: 25px;
    }
  }
`;

export const VideoSide = styled.div`
  h1 {
    color: #021027;
    font-family: 'Inter';
    font-size: 36px;
    font-weight: 500;
  }
  p {
    color: #333;
    font-family: 'Inter';
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
  }

  video {
    border-radius: 25px;
    border: 1px solid #303030;

    box-shadow: 0px 19px 60px 0px rgba(0, 0, 0, 0.12);
  }

  .btn-download {
    color: #fff;
    text-align: center;
    font-family: 'Archivo';
    font-size: 26px;
    font-weight: 700;
    letter-spacing: -0.6px;
    border-radius: 22px;
    border: 2px solid #fff;
    padding: 14.5px 20px;
    background: #116bff;
    transition: all 0.2s ease-in-out !important;
    line-height: 38px;
    :hover {
      opacity: 0.8 !important;
    }
  }

  @media screen and (min-width: 992px) {
    max-width: 1020px;

    .btn-download {
      font-size: 30px;
    }
  }
`;

export const MemberSide = styled.div`
  min-height: calc(100vh - 62px);
  background-color: #fff;

  .welcome {
    background-color: #f9f9f9;
    border-radius: 20px;
    padding: 18px;

    h2 {
      color: #626262;
      font-family: 'Inter';
      font-size: 14px;
      font-weight: 400;
      span {
        color: #021027;
        font-size: 16px;
        font-weight: 700;
      }
    }

    h3 {
      color: #242424;
      font-family: 'Inter';
      font-size: 12px;
      font-weight: 400;
    }

    .btn-connection {
      background: #116bff;
      border-radius: 10px;
      color: #fff;

      font-family: 'Inter';
      font-size: 16px;
      font-weight: 500;
      border: none;
      width: 100%;
      padding: 7px;
    }

    .disabled {
      filter: grayscale(1);
      opacity: 0.5;
      pointer-events: none;
    }

    .pulse {
      -webkit-animation: pulse 1s linear infinite both;
      animation: pulse 1s linear infinite both;
    }

    @keyframes pulse {
      0% {
        transform: scale(1);
      }

      100% {
        transform: scale(1.1);
      }
    }

    @media screen and (min-width: 1200px) {
      background-color: #f9f9f9;
    }
  }

  .member {
    h2 {
      color: #021027;
      font-family: 'Inter';
    }

    .founder-icon {
      width: 30px;
      height: 30px;
    }

    .pad-waitlist {
      background: #f9f9f9;

      border-radius: 0 0 20px 20px;
      padding: 15px;
    }

    .text-copy {
      color: #8c8c8c;
      font-family: 'Inter';
      font-size: 13px;
      font-weight: 400;
    }
    .new {
      font-weight: 400;
      font-size: 14px;
      color: #8c8c8c;
      font-family: 'Inter';
    }

    .title-waitlist {
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      color: #242526;
    }

    .bg-gray {
      background: #f9f9f9;
      border-radius: 0 0 20px 20px;
    }

    .bg-title {
      background: #dedede;
      border-radius: 20px 20px 0px 0px;
      padding: 16px 22px;
    }

    .boxes {
      div {
        background: #fff;
        border: 2px solid #f1f1f1;
        border-radius: 20px;
        padding: 15px 17px;
      }
      h3 {
        color: #242526;

        text-align: center;
        font-family: 'Inter';
        font-size: 16px;
        font-weight: 300;
      }

      p {
        color: #8c8c8c;
        font-family: 'Archivo';
        font-size: 16px;
        margin-bottom: 0;
        font-weight: 300;
        text-align: start;
        span {
          color: #151517;
          font-weight: 500;
        }
      }
    }

    @media screen and (min-width: 1200px) {
      .boxes {
        div {
          background: #ffffff;
          border: 2px solid #f1f1f1;
        }
      }
    }
  }

  .btn-copy {
    background-color: transparent;
    border: solid 1.5px #dedede;
    border-radius: 15px;
    height: 56px;
    font-size: 13px;
    color: #626262;
    font-weight: 300;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
    font-family: 'Inter';

    :hover {
      color: ${darken(0.1, '#626262')};
      border: 1.5px solid ${darken(0.1, '#dedede')};
      transition-duration: 0.5s;
    }

    :disabled {
      pointer-events: none;
    }
  }

  @media screen and (min-width: 992px) {
    width: 322px;
  }
`;

export const AvatarWaitlist = styled.div<IAvatarProps>`
  margin: 0 auto;
  width: 64px;
  height: 64px;
  background-image: url(${(props) => props.src});
  background-position: center;
  background-size: cover;
  border-radius: 50%;
  box-shadow: inset 0px 0px 0px 4px rgba(228, 228, 228, 0.5);
  box-sizing: border-box;
  filter: drop-shadow(0px 0px 7px rgba(0, 0, 0, 0.24));
  background-color: #303030;
`;

export const CopyAlert = styled.div<ICopyAlertProps>`
  transition-duration: 0.3s;
  position: fixed;
  right: ${(props) => (props.show ? '25px' : '-150px')};
  bottom: 50px;
`;
